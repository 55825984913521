/* 
    ## DESKTOP 17''
*/
@media (min-width: 1920px) {

    .book-global-md{
        width : 100%;
        min-width: 100%;
        padding: 0;
        margin: 1em 0em 1em 0em;
        background-color: white;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
    }

    .book-content-transaction-group{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width : 25%;
        min-width: 25%;
    }

    .book-item-md{
        width : 100%;
        min-width: 100%;
        padding: 0;
        margin: 0.2em 0em 0.2em 0em;
        background-color: white;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
    }

    .book-item-description-md{
        width : 95%;
        min-width: 95%;
        padding: 0;
        margin: 0;
        background-color: white;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
        border-style: solid;
        border-color: #17824e;
        border-radius: 1em;
    }

    .book-item-price-md{
        width : 60%;
        min-width: 60%;
        padding: 0;
        margin: 0;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
    }

    .book-item-owner-md{
        color: #353A3E;
        font-weight: bold;
        font-size: 0.73em;
        margin: 0em 1em 0em 1em;
    }

    .book-item-amount-md{
        color: #17824e;
        font-size: 0.73em;
        margin: 0em;
    }

    .book-item-picture-md{
        width : 2vw;
        min-width: 2vw;
        height: 2vw;
        min-height: 2vw;
        background-repeat: 'no-repeat';
        object-fit : 'cover';
        margin: 0.5em 1em 0.5em 1em;
    }

    .book-item-text-input-md{
        width : 2vw;
        min-width: 2vw;
        color: #17824e;
        font-size: 0.73em;
        border-radius: 0.6em;
    }

    .book-item-unit-price-input-md{
        width : 4vw;
        min-width: 4vw;
        color: #17824e;
        font-size: 0.73em;
        border-radius: 0.6em;
    }

    .book-item-side-md{
        width : 40%;
        min-width: 40%;
        padding: 0;
        margin: 0;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
    }

    .book-item-button-md{
        width : 1.2vw;
        min-width: 1.2vw;
        height : 1.2vw;
        min-height : 1.2vw;
        padding: 0;
        margin: 1em;
        background-color: #17824e;
        border-style: none;
        border-radius: 0.6em;
        color: white;
        font-size: 0.73em;
        transition: all .2s ease-in-out;
    }

    .book-item-button-md:hover{
        box-shadow: 0.2vw 0.2vw 0.1vw 0.05vw rgba(141, 141, 139, 0.3);
        transform: scale(1.05);
        opacity: 0.8;
    }

    .book-item-button-border-md{
        width : 1.2vw;
        min-width: 1.2vw;
        height : 1.2vw;
        min-height : 1.2vw;
        padding: 0;
        margin: 1em;
        background-color: white;
        border-style: double;
        border-color: #17824e;
        border-radius: 0.6em;
        color: #17824e;
        font-size: 0.73em;
        transition: all .2s ease-in-out;
    }

    .book-item-button-border-md:hover{
        box-shadow: 0.2vw 0.2vw 0.1vw 0.05vw rgba(141, 141, 139, 0.3);
        transform: scale(1.05);
        opacity: 0.8;
    }

}

/* 
    ## DESKTOP 15''
*/
@media (min-width: 1020px) and (max-width: 1919px) {

    .book-global-md{
        width : 100%;
        min-width: 100%;
        padding: 0;
        margin: 1em 0em 1em 0em;
        background-color: white;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
    }

    .book-content-transaction-group{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width : 25%;
        min-width: 25%;
    }

    .book-item-md{
        width : 100%;
        min-width: 100%;
        padding: 0;
        margin: 0.2em 0em 0.2em 0em;
        background-color: white;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
    }

    .book-item-description-md{
        width : 95%;
        min-width: 95%;
        padding: 0;
        margin: 0;
        background-color: white;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
        border-style: solid;
        border-color: #17824e;
        border-radius: 1em;
    }

    .book-item-price-md{
        width : 60%;
        min-width: 60%;
        padding: 0;
        margin: 0;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
    }

    .book-item-owner-md{
        color: #353A3E;
        font-weight: bold;
        font-size: 0.73em;
        margin: 0em 0em 0em 1em;
    }

    .book-item-amount-md{
        color: #17824e;
        font-size: 0.73em;
        margin: 0em 1em 0em 1em;
    }

    .book-item-picture-md{
        width : 2vw;
        min-width: 2vw;
        height: 2vw;
        min-height: 2vw;
        background-repeat: 'no-repeat';
        object-fit : 'cover';
        margin: 0.5em 0em 0.5em 0em;
    }

    .book-item-text-input-md{
        width : 3vw;
        min-width: 3vw;
        color: #17824e;
        font-size: 0.73em;
        border-radius: 0.6em;
    }

    .book-item-unit-price-input-md{
        width : 6vw;
        min-width: 6vw;
        color: #17824e;
        font-size: 0.73em;
        border-radius: 0.6em;
    }

    .book-item-side-md{
        width : 40%;
        min-width: 40%;
        padding: 0;
        margin: 0;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
    }

    .book-item-button-md{
        width : 1.2vw;
        min-width: 1.2vw;
        height : 1.2vw;
        min-height : 1.2vw;
        padding: 0;
        margin: 1em;
        background-color: #17824e;
        border-style: none;
        border-radius: 0.6em;
        color: white;
        font-size: 0.73em;
        transition: all .2s ease-in-out;
    }

    .book-item-button-md:hover{
        box-shadow: 0.2vw 0.2vw 0.1vw 0.05vw rgba(141, 141, 139, 0.3);
        transform: scale(1.05);
        opacity: 0.8;
    }

    .book-item-button-border-md{
        width : 1.2vw;
        min-width: 1.2vw;
        height : 1.2vw;
        min-height : 1.2vw;
        padding: 0;
        margin: 1em;
        background-color: white;
        border-style: double;
        border-color: #17824e;
        border-radius: 0.6em;
        color: #17824e;
        font-size: 0.73em;
        transition: all .2s ease-in-out;
    }

    .book-item-button-border-md:hover{
        box-shadow: 0.2vw 0.2vw 0.1vw 0.05vw rgba(141, 141, 139, 0.3);
        transform: scale(1.05);
        opacity: 0.8;
    }

}

/* 
    ## IPAD
*/
@media (min-width: 820px) and (max-width: 1019px) {

    .book-global-md{
        width : 100%;
        min-width: 100%;
        padding: 0;
        margin: 1em 0em 1em 0em;
        background-color: white;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
    }

    .book-content-transaction-group{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width : 25%;
        min-width: 25%;
    }

    .book-item-md{
        width : 100%;
        min-width: 100%;
        padding: 0;
        margin: 1vw 0vw 1vw 0vw;
        background-color: white;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
    }

    .book-item-picture-md{
        width : 3vw;
        min-width: 3vw;
        height: 3vw;
        min-height: 3vw;
        background-repeat: 'no-repeat';
        object-fit : 'cover';
        margin: 1vw 1vw 1vw 1vw;
    }

    .book-item-description-md{
        width : 95%;
        min-width: 95%;
        padding: 0;
        margin: 0;
        background-color: white;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
        border-style: solid;
        border-color: #17824e;
        border-radius: 1em;
    }

    .book-item-price-md{
        width : 30%;
        min-width: 30%;
        padding: 0;
        margin: 0;

        display : flex;
        flex-direction : column;
        justify-content: center;
        align-items: center;
    }

    .book-item-text-bold-md{
        color: #353A3E;
        font-weight: bold;
        font-size: 0.73em;
    }

    .book-item-text-md{
        color: #17824e;
        font-size: 0.73em;
    }

    .book-item-text-input-md{
        width : 7vw;
        min-width: 7vw;
        color: #17824e;
        font-size: 0.73em;
        border-radius: 0.6em;
    }

    .book-item-unit-price-input-md{
        width : 14vw;
        min-width: 14vw;
        color: #17824e;
        font-size: 0.73em;
        border-radius: 0.6em;
    }

    .book-item-side-md{
        width : 50%;
        min-width: 50%;
        padding: 0;
        margin: 0;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
    }

    .book-item-button-md{
        width : 3vw;
        min-width: 3vw;
        height : 3vw;
        min-height : 3vw;
        padding: 0;
        margin: 0.5vw;
        background-color: #17824e;
        border-style: none;
        border-radius: 0.6em;
        color: white;
        font-size: 0.73em;
        box-shadow: 0.5vh 0.5vh 0.25vh rgba(105, 105, 105, 0.5);
    }

    .book-item-button-border-md{
        width : 3vw;
        min-width: 3vw;
        height : 3vw;
        min-height : 3vw;
        padding: 0;
        margin: 0.5vw;
        background-color: white;
        border-style: double;
        border-color: #17824e;
        border-radius: 0.6em;
        color: #17824e;
        font-size: 0.73em;
        box-shadow: 0.5vh 0.5vh 0.25vh rgba(105, 105, 105, 0.5);
    }

}


/* 
    ## SMARTPHONE
*/
@media (max-width: 819px) {

    .book-global-md{
        width : 100%;
        min-width: 100%;
        padding: 0;
        margin: 1em 0em 1em 0em;
        background-color: white;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
    }

    .book-content-transaction-group{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width : 25%;
        min-width: 25%;
    }

    .book-item-md{
        width : 100%;
        min-width: 100%;
        padding: 0;
        margin: 0.2em 0em 0.2em 0em;
        background-color: white;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
    }

    .book-item-picture-md{
        width : 4vw;
        min-width: 4vw;
        height: 4vw;
        min-height: 4vw;
        background-repeat: 'no-repeat';
        object-fit : 'cover';
        margin: 1vw 1vw 1vw 1vw;
    }

    .book-item-description-md{
        width : 95%;
        min-width: 95%;
        padding: 0;
        margin: 0;
        background-color: white;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
        border-style: solid;
        border-color: #17824e;
        border-radius: 1em;
    }

    .book-item-price-md{
        width : 35%;
        min-width: 35%;
        padding: 0;
        margin: 0;

        display : flex;
        flex-direction : column;
        justify-content: center;
        align-items: center;
    }

    .book-item-text-bold-md{
        color: #353A3E;
        font-weight: bold;
        font-size: 0.73em;
    }

    .book-item-text-md{
        color: #17824e;
        font-size: 0.73em;
    }

    .book-item-text-input-md{
        width : 11vw;
        min-width: 11vw;
        color: #17824e;
        font-size: 0.73em;
        border-radius: 0.6em;
    }

    .book-item-unit-price-input-md{
        width : 22vw;
        min-width: 22vw;
        color: #17824e;
        font-size: 0.73em;
        border-radius: 0.6em;
    }

    .book-item-side-md{
        width : 45%;
        min-width: 45%;
        padding: 0;
        margin: 0;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
    }

    .book-item-button-md{
        width : 6vw;
        min-width: 6vw;
        height : 6vw;
        min-height : 6vw;
        padding: 0;
        margin: 0.5vw;
        background-color: #17824e;
        border-style: none;
        border-radius: 0.6em;
        color: white;
        font-size: 0.73em;
        box-shadow: 0.5vh 0.5vh 0.25vh rgba(105, 105, 105, 0.5);
    }

    .book-item-button-border-md{
        width : 6vw;
        min-width: 6vw;
        height : 6vw;
        min-height : 6vw;
        padding: 0;
        margin: 0.5vw;
        background-color: white;
        border-style: double;
        border-color: #17824e;
        border-radius: 0.6em;
        color: #17824e;
        font-size: 0.73em;
        box-shadow: 0.5vh 0.5vh 0.25vh rgba(105, 105, 105, 0.5);
    }

}