
.goals-block-50-left{
    min-width: 50%;
    width: 50%;
    margin: 0;
    padding: 0;
    display : flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.goals-block-50-right{
    min-width: 50%;
    width: 50%;
    margin: 0;
    padding: 0;
    display : flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}


/* 
    ## DESKTOP 17''
*/
@media (min-width: 1920px) {

    .goals-icon{
        width : 10vw;
        min-width: 10vw;
        height : 10vw;
        min-height : 10vw;
        background-repeat: 'no-repeat';
        object-fit : cover;
        padding: 0;
        border-radius: 0.5vw;
    }

    .goals-img{
        width : 35vw;
        min-width: 35vw;
        height : 45vw;
        min-height : 45vw;
        background-repeat: 'no-repeat';
        object-fit : cover;
        padding: 0;
        border-radius: 0.5vw;
    }

    .goals-step{
        width : 15vw;
        min-width: 15vw;
        min-height : 20vw;
        background-repeat: 'no-repeat';
        object-fit : cover;
        padding: 0;
        border-radius: 0.5vw;
    }

    .goals-step-xl{
        width : 45vw;
        min-width: 45vw;
        min-height : 35vw;
        background-repeat: 'no-repeat';
        object-fit : cover;
        padding: 0;
        border-radius: 0.5vw;
    }

}

/* 
    ## DESKTOP 15''
*/
@media (min-width: 1020px) and (max-width: 1919px) {

    .goals-icon{
        width : 10vw;
        min-width: 10vw;
        height : 10vw;
        min-height : 10vw;
        background-repeat: 'no-repeat';
        object-fit : cover;
        padding: 0;
        border-radius: 0.5vw;
    }

    .goals-img{
        width : 35vw;
        min-width: 35vw;
        height : 45vw;
        min-height : 45vw;
        background-repeat: 'no-repeat';
        object-fit : cover;
        padding: 0;
        border-radius: 0.5vw;
    }

    .goals-step{
        width : 15vw;
        min-width: 15vw;
        min-height : 20vw;
        background-repeat: 'no-repeat';
        object-fit : cover;
        padding: 0;
        border-radius: 0.5vw;
    }

    .goals-step-xl{
        width : 45vw;
        min-width: 45vw;
        min-height : 35vw;
        background-repeat: 'no-repeat';
        object-fit : cover;
        padding: 0;
        border-radius: 0.5vw;
    }

}

/* 
    ## IPAD
*/
@media (min-width: 820px) and (max-width: 1019px) {

    .goals-icon{
        width : 20vw;
        min-width: 20vw;
        height : 20vw;
        min-height : 20vw;
        background-repeat: 'no-repeat';
        object-fit : cover;
        padding: 0;
    }

    .goals-img{
        width : 50vw;
        min-width: 50vw;
        height : 55vw;
        min-height : 55vw;
        background-repeat: 'no-repeat';
        object-fit : cover;
        padding: 0;
        border-radius: 0.5vw;
    }

    .goals-step{
        width : 25vw;
        min-width: 25vw;
        min-height : 30vw;
        background-repeat: 'no-repeat';
        object-fit : cover;
        padding: 0;
        border-radius: 1vw;
    }

    .goals-step-xl{
        width : 60vw;
        min-width: 60vw;
        min-height : 50vw;
        background-repeat: 'no-repeat';
        object-fit : cover;
        padding: 0;
        border-radius: 1vw;
    }


}

/* 
    ## SMARTPHONE
*/
@media (max-width: 819px) {

    .goals-icon{
        width : 20vw;
        min-width: 20vw;
        height : 20vw;
        min-height : 20vw;
        background-repeat: 'no-repeat';
        object-fit : cover;
        padding: 0;
    }

    .goals-img{
        width : 45vw;
        min-width: 45vw;
        height : 55vw;
        min-height : 55vw;
        background-repeat: 'no-repeat';
        object-fit : cover;
        padding: 0;
        border-radius: 0.5vw;
    }

    .goals-step{
        width : 60vw;
        min-width: 60vw;
        min-height : 80vw;
        background-repeat: 'no-repeat';
        object-fit : cover;
        padding: 0;
        border-radius: 1vw;
    }

    .goals-step-xl{
        width : 80vw;
        min-width: 80vw;
        min-height : 60vw;
        background-repeat: 'no-repeat';
        object-fit : cover;
        padding: 0;
        border-radius: 1vw;
    }

}