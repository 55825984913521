
/* 
    ## DESKTOP 17''
*/
@media (min-width: 1920px) {

  .marketplace-space{
    height: 5vw;
    min-height: 5vw;
  }

}

/* 
  ## DESKTOP 15''
*/
@media (min-width: 1020px) and (max-width: 1919px) {

  .marketplace-space{
    height: 8vw;
    min-height: 8vw;
  }
  
}

/* 
  ## IPAD
*/
@media (min-width: 820px) and (max-width: 1019px) {

  .marketplace-space{
    height: 12vw;
    min-height: 12vw;
  }
  
}

/* 
  ## SMARTPHONE
*/
@media (max-width: 819px) {

  .marketplace-space{
    height: 20vw;
    min-height: 20vw;
  }
  
}

.marketplace-all-container{
    width : 100%;
    min-width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}


.marketplace-container{
    width : 100%;
    min-width: 100%;
    padding: 0;
    margin: 0;
    display : flex;
    flex-direction : column;
    justify-content: center;
    align-items: center;
}

.marketplace-row{
    width : 100%;
    min-width: 100%;
    padding: 0;
    margin: 0;
    display : flex;
    flex-direction : row;
    justify-content: center;
    align-items: center;
}

.scrollbar {
    float: left;
    background: transparent;
    overflow-y: scroll;
}

.scrollbar-steel {
    scrollbar-color: #f1f1ef #17824e;
    border-radius: 10px; 
    background: transparent;
}
  
.scrollbar-steel::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);

  border-radius: 10px; 
  background: transparent;
}
  
.scrollbar-steel::-webkit-scrollbar {
  width: 12px;
  background: transparent;
}
  
.scrollbar-steel::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(330deg, #f1f1ef 0%, #17824e 100%);
  background-image: linear-gradient(120deg, #f1f1ef 0%, #17824e 100%); 
}