

/* 
    ## DESKTOP 17''
*/
@media (min-width: 1920px) {

    .booking-view-container{
        min-width: 80vw;
        width: 80vw;
        min-height: 80vh;
        height: 80vh;
        margin: 10vh 0vh 0vh 0vh;
        padding: 0px;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-radius: 0.5vw;
        background-color: white;
        border-style: solid;
        border-color: #48b27f;
    }

    .booking-view-header{
        min-width: 100%;
        width: 100%;
        min-height: 10vh;
        height: 10vh;
        margin: 0px;
        padding: 0px;

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;
    }

    .booking-view-body{
        min-width: 100%;
        width: 100%;
        min-height: 60vh;
        height: 60vh;
        margin: 0px;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .booking-view-footer{
        min-width: 100%;
        width: 100%;
        min-height: 10vh;
        height: 10vh;
        margin: 0px;
        padding: 0px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

}

/* 
    ## DESKTOP 15''
*/
@media (min-width: 1020px) and (max-width: 1919px) {

    .booking-view-container{
        min-width: 80vw;
        width: 80vw;
        min-height: 80vh;
        height: 80vh;
        margin: 10vh 0vh 0vh 0vh;
        padding: 0px;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-radius: 0.5vw;
        background-color: white;
        border-style: solid;
        border-color: #48b27f;
    }

    .booking-view-header{
        min-width: 100%;
        width: 100%;
        min-height: 10vh;
        height: 10vh;
        margin: 0px;
        padding: 0px;

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;
    }

    .booking-view-body{
        min-width: 100%;
        width: 100%;
        min-height: 60vh;
        height: 60vh;
        margin: 0px;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .booking-view-footer{
        min-width: 100%;
        width: 100%;
        min-height: 10vh;
        height: 10vh;
        margin: 0px;
        padding: 0px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

}

/* 
    ## IPAD
*/
@media (min-width: 820px) and (max-width: 1019px) {

    .booking-view-container{
        min-width: 90vw;
        width: 90vw;
        min-height: 90vh;
        height: 90vh;
        margin: 5vh 0vh 0vh 0vh;
        padding: 0px;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-radius: 0.5vw;
        background-color: white;
        border-style: solid;
        border-color: #48b27f;
    }

    .booking-view-header{
        min-width: 100%;
        width: 100%;
        min-height: 4vh;
        height: 4vh;
        margin: 0px;
        padding: 0px;

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }

    .booking-view-body{
        min-width: 100%;
        width: 100%;
        min-height: 79vh;
        height: 79vh;
        margin: 0px;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .booking-view-footer{
        min-width: 100%;
        width: 100%;
        min-height: 7vh;
        height: 7vh;
        margin: 0px;
        padding: 0px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

}


/* 
    ## SMARTPHONE
*/
@media (max-width: 819px) {

    .booking-view-container{
        min-width: 95vw;
        width: 95vw;
        min-height: 95vh;
        height: 95vh;
        margin: 5vh 0vh 0vh 0vh;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-radius: 0.5vw;
        background-color: white;
        border-style: solid;
        border-color: #48b27f;
    }

    .booking-view-header{
        min-width: 100%;
        width: 100%;
        min-height: 4vh;
        height: 4vh;
        margin: 0px;
        padding: 0px;

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }

    .booking-view-body{
        min-width: 100%;
        width: 100%;
        min-height: 84vh;
        height: 84vh;
        margin: 0px;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .booking-view-footer{
        min-width: 100%;
        width: 100%;
        min-height: 7vh;
        height: 7vh;
        margin: 0px;
        padding: 0px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

}