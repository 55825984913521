
/* 
    ## DESKTOP 17''
*/
@media (min-width: 1920px) {

    .text-component-sm-dark{
        color : #696969;
        font-size: 0.5vw;
    }

    .text-component-sm-light{
        color: white;
        font-size: 0.5vw;
    }

    .text-component-md-dark{
        color : #696969;
        font-size: 0.8vw;
    }

    .text-component-md-light{
        color: white;
        font-size: 0.8vw;
    }

    .text-component-lg-dark{
        color : #696969;
        font-size: 1.5vw;
    }

    .text-component-lg-light{
        color: white;
        font-size: 1.5vw;
    }
 
}

/* 
    ## DESKTOP 15''
*/
@media (min-width: 1020px) and (max-width: 1919px) {

    .text-component-sm-dark{
        color : #696969;
        font-size: 0.5vw;
    }

    .text-component-sm-light{
        color: white;
        font-size: 0.5vw;
    }

    .text-component-md-dark{
        color : #696969;
        font-size: 0.8vw;
    }

    .text-component-md-light{
        color: white;
        font-size: 0.8vw;
    }

    .text-component-lg-dark{
        color : #696969;
        font-size: 1.5vw;
    }

    .text-component-lg-light{
        color: white;
        font-size: 1.5vw;
    }

}

/* 
    ## IPAD
*/
@media (min-width: 820px) and (max-width: 1019px) {

    .text-component-sm-dark{
        color : #696969;
        font-size: 1vw;
    }

    .text-component-sm-light{
        color: white;
        font-size: 1vw;
    }

    .text-component-md-dark{
        color : #696969;
        font-size: 1.5vw;
    }

    .text-component-md-light{
        color: white;
        font-size: 1.5vw;
    }

    .text-component-lg-dark{
        color : #696969;
        font-size: 2vw;
    }

    .text-component-lg-light{
        color: white;
        font-size: 2vw;
    }

}

/* 
    ## SMARTPHONE
*/
@media (max-width: 819px) {

    .text-component-sm-dark{
        color : #696969;
        font-size: 2vw;
    }

    .text-component-sm-light{
        color: white;
        font-size: 2vw;
    }

    .text-component-md-dark{
        color : #696969;
        font-size: 2vw;
    }

    .text-component-md-light{
        color: white;
        font-size: 2vw;
    }

    .text-component-lg-dark{
        color : #696969;
        font-size: 3vw;
    }

    .text-component-lg-light{
        color: white;
        font-size: 3vw;
    }

}