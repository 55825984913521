
/* 
    ## DESKTOP 17''
*/
@media (min-width: 1920px) {

    .search-component-container{
        width : 75vw;
        min-width: 75vw;
        height : 4vw;
        min-height : 4vw;
        padding: 0;
        margin: 2vw 0vw 2vw 0vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .search-component-dropdown{
        width : 20vw;
        min-width: 20vw;
        height : 16vw;
        min-height : 16vw;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 0.6em;
        border-style: solid;
        border-color: #48b27f;
        position: absolute;
        z-index: 2;
        top: 12vw;
    }

    .search-component-left{
        width : 15vw;
        min-width: 15vw;
        height : 4vw;
        min-height : 4vw;
        padding: 0;
        margin: 0;
        border-style: solid;
        border-right: none;
        border-width: 0.2em;
        border-color: #17824e;
        border-top-left-radius: 0.6em;
        border-bottom-left-radius: 0.6em;
        background-color: white;
        transition: all .2s ease-in-out;
    }

    .search-component-left:hover{
        border-color: #a4efcb;
        background-color: #a4efcb;
        z-index: 1;
    }

    .search-component-left-selected{
        animation: blink-selected 1s normal;
    }

    .search-component-left-clicked{
        animation: blink-clicked 1s normal;
    }

    .search-component-middle{
        width : 15vw;
        min-width: 15vw;
        height : 4vw;
        min-height : 4vw;
        padding: 0;
        margin: 0;
        border-style: solid;
        border-left: none;
        border-right: none;
        border-width: 0.2em;
        border-color: #17824e;
        background-color: white;
        transition: all .2s ease-in-out;
    }

    .search-component-middle:hover{
        border-color: #a4efcb;
        background-color: #a4efcb;
        z-index: 1;
    }

    .search-component-middle-selected{
        animation: blink-selected 1s normal;
    }

    .search-component-middle-clicked{
        animation: blink-clicked 1s normal;
    }

    .search-component-right{
        width : 15vw;
        min-width: 15vw;
        height : 4vw;
        min-height : 4vw;
        padding: 0;
        margin: 0;
        border-style: solid;
        border-left: none;
        border-width: 0.2em;
        border-color: #17824e;
        border-top-right-radius: 0.6em;
        border-bottom-right-radius: 0.6em;
        background-color: white;
        transition: all .2s ease-in-out;
    }

    .search-component-right:hover{
        border-color: #a4efcb;
        background-color: #a4efcb;
        z-index: 1;
    }

    .search-component-right-selected{
        animation: blink-selected 1s normal;
    }

    .search-component-right-clicked{
        animation: blink-clicked 1s normal;
    }

    @keyframes blink-selected {
        from { opacity: 0 }
        to { opacity: 1 }
    }

    @keyframes blink-clicked {
        from { opacity: 0 }
        to { opacity: 1 }
    }

    .search-item-picture-md{
        width : 2vw;
        min-width: 2vw;
        height: 2vw;
        min-height: 2vw;
        background-repeat: 'no-repeat';
        object-fit : 'cover';
        margin: 0.5em 1em 0.5em 1em;
    }

    .search-item-text-input-md{
        width : 7vw;
        min-width: 7vw;
        color: #17824e;
        font-size: 0.73em;
        border-radius: 0.6em;
    }

    .search-item-button-md{
        width : 1.2vw;
        min-width: 1.2vw;
        height : 1.2vw;
        min-height : 1.2vw;
        padding: 0;
        margin: 1em;
        background-color: #17824e;
        border-style: none;
        border-radius: 0.6em;
        color: white;
        font-size: 0.73em;
        transition: all .2s ease-in-out;
    }

    .search-item-button-md:hover{
        box-shadow: 0.2vw 0.2vw 0.1vw 0.05vw rgba(141, 141, 139, 0.3);
        transform: scale(1.05);
        opacity: 0.8;
    }

    .search-item-button-border-md{
        width : 1.2vw;
        min-width: 1.2vw;
        height : 1.2vw;
        min-height : 1.2vw;
        padding: 0;
        margin: 1em;
        background-color: white;
        border-style: double;
        border-color: #17824e;
        border-radius: 0.6em;
        color: #17824e;
        font-size: 0.73em;
        transition: all .2s ease-in-out;
    }

    .search-item-button-border-md:hover{
        box-shadow: 0.2vw 0.2vw 0.1vw 0.05vw rgba(141, 141, 139, 0.3);
        transform: scale(1.05);
        opacity: 0.8;
    }
 
}

/* 
    ## DESKTOP 15''
*/
@media (min-width: 1020px) and (max-width: 1919px) {

    .search-component-container{
        width : 75vw;
        min-width: 75vw;
        height : 4vw;
        min-height : 4vw;
        padding: 0;
        margin: 2vw 0vw 2vw 0vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .search-component-dropdown{
        width : 20vw;
        min-width: 20vw;
        height : 16vw;
        min-height : 16vw;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 0.6em;
        border-style: solid;
        border-color: #48b27f;
        position: absolute;
        z-index: 2;
        top: 12vw;
    }

    .search-component-left{
        width : 15vw;
        min-width: 15vw;
        height : 4vw;
        min-height : 4vw;
        padding: 0;
        margin: 0;
        border-style: solid;
        border-right: none;
        border-width: 0.2em;
        border-color: #17824e;
        border-top-left-radius: 0.6em;
        border-bottom-left-radius: 0.6em;
        background-color: white;
        transition: all .2s ease-in-out;
    }

    .search-component-left:hover{
        border-color: #a4efcb;
        background-color: #a4efcb;
        z-index: 1;
    }

    .search-component-left-selected{
        animation: blink-selected 1s normal;
    }

    .search-component-left-clicked{
        animation: blink-clicked 1s normal;
    }

    .search-component-middle{
        width : 15vw;
        min-width: 15vw;
        height : 4vw;
        min-height : 4vw;
        padding: 0;
        margin: 0;
        border-style: solid;
        border-left: none;
        border-right: none;
        border-width: 0.2em;
        border-color: #17824e;
        background-color: white;
        transition: all .2s ease-in-out;
    }

    .search-component-middle:hover{
        border-color: #a4efcb;
        background-color: #a4efcb;
        z-index: 1;
    }

    .search-component-middle-selected{
        animation: blink-selected 1s normal;
    }

    .search-component-middle-clicked{
        animation: blink-clicked 1s normal;
    }

    .search-component-right{
        width : 15vw;
        min-width: 15vw;
        height : 4vw;
        min-height : 4vw;
        padding: 0;
        margin: 0;
        border-style: solid;
        border-left: none;
        border-width: 0.2em;
        border-color: #17824e;
        border-top-right-radius: 0.6em;
        border-bottom-right-radius: 0.6em;
        background-color: white;
        transition: all .2s ease-in-out;
    }

    .search-component-right:hover{
        border-color: #a4efcb;
        background-color: #a4efcb;
        z-index: 1;
    }

    .search-component-right-selected{
        animation: blink-selected 1s normal;
    }

    .search-component-right-clicked{
        animation: blink-clicked 1s normal;
    }

    @keyframes blink-selected {
        from { opacity: 0 }
        to { opacity: 1 }
    }

    @keyframes blink-clicked {
        from { opacity: 0 }
        to { opacity: 1 }
    }

    .search-item-picture-md{
        width : 2vw;
        min-width: 2vw;
        height: 2vw;
        min-height: 2vw;
        background-repeat: 'no-repeat';
        object-fit : 'cover';
        margin: 0.5em 0em 0.5em 0em;
    }

    .search-item-text-input-md{
        width : 7vw;
        min-width: 7vw;
        color: #17824e;
        font-size: 0.73em;
        border-radius: 0.6em;
    }

    .search-item-button-md{
        width : 1.2vw;
        min-width: 1.2vw;
        height : 1.2vw;
        min-height : 1.2vw;
        padding: 0;
        margin: 1em;
        background-color: #17824e;
        border-style: none;
        border-radius: 0.6em;
        color: white;
        font-size: 0.73em;
        transition: all .2s ease-in-out;
    }

    .search-item-button-md:hover{
        box-shadow: 0.2vw 0.2vw 0.1vw 0.05vw rgba(141, 141, 139, 0.3);
        transform: scale(1.05);
        opacity: 0.8;
    }

    .search-item-button-border-md{
        width : 1.2vw;
        min-width: 1.2vw;
        height : 1.2vw;
        min-height : 1.2vw;
        padding: 0;
        margin: 1em;
        background-color: white;
        border-style: double;
        border-color: #17824e;
        border-radius: 0.6em;
        color: #17824e;
        font-size: 0.73em;
        transition: all .2s ease-in-out;
    }

    .search-item-button-border-md:hover{
        box-shadow: 0.2vw 0.2vw 0.1vw 0.05vw rgba(141, 141, 139, 0.3);
        transform: scale(1.05);
        opacity: 0.8;
    }

}

/* 
    ## IPAD
*/
@media (min-width: 820px) and (max-width: 1019px) {

    .search-component-container{
        width : 85vw;
        min-width: 85vw;
        height : 8vw;
        min-height : 8vw;
        padding: 0;
        margin: 2vw 0vw 2vw 0vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .search-component-dropdown{
        width : 25vw;
        min-width: 25vw;
        height : 30vw;
        min-height : 30vw;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 0.6em;
        border-style: solid;
        border-color: #48b27f;
        position: absolute;
        z-index: 2;
        top: 22vw;
    }

    .search-component-left{
        width : 17vw;
        min-width: 17vw;
        height : 8vw;
        min-height : 8vw;
        padding: 0;
        margin: 0;
        border-style: solid;
        border-right: none;
        border-width: 0.2em;
        border-color: #17824e;
        border-top-left-radius: 0.6em;
        border-bottom-left-radius: 0.6em;
        background-color: white;
        transition: all .2s ease-in-out;
    }

    .search-component-left:hover{
        border-color: #a4efcb;
        background-color: #a4efcb;
        z-index: 1;
    }

    .search-component-left-selected{
        animation: blink-selected 1s normal;
    }

    .search-component-left-clicked{
        animation: blink-clicked 1s normal;
    }

    .search-component-middle{
        width : 17vw;
        min-width: 17vw;
        height : 8vw;
        min-height : 8vw;
        padding: 0;
        margin: 0;
        border-style: solid;
        border-left: none;
        border-right: none;
        border-width: 0.2em;
        border-color: #17824e;
        background-color: white;
        transition: all .2s ease-in-out;
    }

    .search-component-middle:hover{
        border-color: #a4efcb;
        background-color: #a4efcb;
        z-index: 1;
    }

    .search-component-middle-selected{
        animation: blink-selected 1s normal;
    }

    .search-component-middle-clicked{
        animation: blink-clicked 1s normal;
    }

    .search-component-right{
        width : 17vw;
        min-width: 17vw;
        height : 8vw;
        min-height : 8vw;
        padding: 0;
        margin: 0;
        border-style: solid;
        border-left: none;
        border-width: 0.2em;
        border-color: #17824e;
        border-top-right-radius: 0.6em;
        border-bottom-right-radius: 0.6em;
        background-color: white;
        transition: all .2s ease-in-out;
    }

    .search-component-right:hover{
        border-color: #a4efcb;
        background-color: #a4efcb;
        z-index: 1;
    }

    .search-component-right-selected{
        animation: blink-selected 1s normal;
    }

    .search-component-right-clicked{
        animation: blink-clicked 1s normal;
    }

    @keyframes blink-selected {
        from { opacity: 0 }
        to { opacity: 1 }
    }

    @keyframes blink-clicked {
        from { opacity: 0 }
        to { opacity: 1 }
    }

    .search-item-picture-md{
        width : 3vw;
        min-width: 3vw;
        height: 3vw;
        min-height: 3vw;
        background-repeat: 'no-repeat';
        object-fit : 'cover';
        margin: 1vw 1vw 1vw 1vw;
    }

    .search-item-text-input-md{
        width : 7vw;
        min-width: 7vw;
        color: #17824e;
        font-size: 0.73em;
        border-radius: 0.6em;
    }

    .search-item-button-md{
        width : 3vw;
        min-width: 3vw;
        height : 3vw;
        min-height : 3vw;
        padding: 0;
        margin: 0.5vw;
        background-color: #17824e;
        border-style: none;
        border-radius: 0.6em;
        color: white;
        font-size: 0.73em;
        box-shadow: 0.5vh 0.5vh 0.25vh rgba(105, 105, 105, 0.5);
    }

    .search-item-button-border-md{
        width : 3vw;
        min-width: 3vw;
        height : 3vw;
        min-height : 3vw;
        padding: 0;
        margin: 0.5vw;
        background-color: white;
        border-style: double;
        border-color: #17824e;
        border-radius: 0.6em;
        color: #17824e;
        font-size: 0.73em;
        box-shadow: 0.5vh 0.5vh 0.25vh rgba(105, 105, 105, 0.5);
    }

}

/* 
    ## SMARTPHONE
*/
@media (max-width: 819px) {

    .search-component-container{
        width : 95vw;
        min-width: 95vw;
        height : 8vw;
        min-height : 8vw;
        padding: 0;
        margin: 2vw 0vw 2vw 0vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .search-component-dropdown{
        width : 30vw;
        min-width: 30vw;
        height : 60vw;
        min-height : 60vw;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 0.6em;
        border-style: solid;
        border-color: #48b27f;
        position: absolute;
        z-index: 2;
        top: 28vw;
    }

    .search-component-left{
        width : 19vw;
        min-width: 19vw;
        height : 8vw;
        min-height : 8vw;
        padding: 0;
        margin: 0;
        border-style: solid;
        border-right: none;
        border-width: 0.2em;
        border-color: #17824e;
        border-top-left-radius: 0.6em;
        border-bottom-left-radius: 0.6em;
        background-color: white;
        transition: all .2s ease-in-out;
    }

    .search-component-left:hover{
        border-color: #a4efcb;
        background-color: #a4efcb;
        z-index: 1;
    }

    .search-component-left-selected{
        animation: blink-selected 1s normal;
    }

    .search-component-left-clicked{
        animation: blink-clicked 1s normal;
    }

    .search-component-middle{
        width : 19vw;
        min-width: 19vw;
        height : 8vw;
        min-height : 8vw;
        padding: 0;
        margin: 0;
        border-style: solid;
        border-left: none;
        border-right: none;
        border-width: 0.2em;
        border-color: #17824e;
        background-color: white;
        transition: all .2s ease-in-out;
    }

    .search-component-middle:hover{
        border-color: #a4efcb;
        background-color: #a4efcb;
        z-index: 1;
    }

    .search-component-middle-selected{
        animation: blink-selected 1s normal;
    }

    .search-component-middle-clicked{
        animation: blink-clicked 1s normal;
    }

    .search-component-right{
        width : 19vw;
        min-width: 19vw;
        height : 8vw;
        min-height : 8vw;
        padding: 0;
        margin: 0;
        border-style: solid;
        border-left: none;
        border-width: 0.2em;
        border-color: #17824e;
        border-top-right-radius: 0.6em;
        border-bottom-right-radius: 0.6em;
        background-color: white;
        transition: all .2s ease-in-out;
    }

    .search-component-right:hover{
        border-color: #a4efcb;
        background-color: #a4efcb;
        z-index: 1;
    }

    .search-component-right-selected{
        animation: blink-selected 1s normal;
    }

    .search-component-right-clicked{
        animation: blink-clicked 1s normal;
    }

    @keyframes blink-selected {
        from { opacity: 0 }
        to { opacity: 1 }
    }

    @keyframes blink-clicked {
        from { opacity: 0 }
        to { opacity: 1 }
    }

    .search-item-picture-md{
        width : 4vw;
        min-width: 4vw;
        height: 4vw;
        min-height: 4vw;
        background-repeat: 'no-repeat';
        object-fit : 'cover';
        margin: 1vw 1vw 1vw 1vw;
    }

    .search-item-text-input-md{
        width : 16vw;
        min-width: 16vw;
        color: #17824e;
        font-size: 0.73em;
        border-radius: 0.6em;
    }

    .search-item-button-md{
        width : 4vw;
        min-width: 4vw;
        height : 4vw;
        min-height : 4vw;
        padding: 0;
        margin: 0.5vw;
        background-color: #17824e;
        border-style: none;
        border-radius: 0.6em;
        color: white;
        font-size: 0.73em;
        box-shadow: 0.5vh 0.5vh 0.25vh rgba(105, 105, 105, 0.5);
    }

    .search-item-button-border-md{
        width : 4vw;
        min-width: 4vw;
        height : 4vw;
        min-height : 4vw;
        padding: 0;
        margin: 0.5vw;
        background-color: white;
        border-style: double;
        border-color: #17824e;
        border-radius: 0.6em;
        color: #17824e;
        font-size: 0.73em;
        box-shadow: 0.5vh 0.5vh 0.25vh rgba(105, 105, 105, 0.5);
    }

}