
/* 
    ## DESKTOP 17''
*/
@media (min-width: 1920px) {

    .whitepaper-space{
      height: 5vw;
      min-height: 5vw;
    }
  
  }
  
  /* 
    ## DESKTOP 15''
  */
  @media (min-width: 1020px) and (max-width: 1919px) {
  
    .whitepaper-space{
      height: 8vw;
      min-height: 8vw;
    }
    
  }
  
  /* 
    ## IPAD
  */
  @media (min-width: 820px) and (max-width: 1019px) {
  
    .whitepaper-space{
      height: 12vw;
      min-height: 12vw;
    }
    
  }
  
  /* 
    ## SMARTPHONE
  */
  @media (max-width: 819px) {
  
    .whitepaper-space{
      height: 20vw;
      min-height: 20vw;
    }
    
  }