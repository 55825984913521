
/* 
    ## DESKTOP 17''
*/
@media (min-width: 1920px) {

    .nft-md{
        width : 20vw;
        min-width: 20vw;
        height : 25vw;
        min-height : 25vw;
        padding: 0;
        margin: 1vw;
        margin-bottom: 2vw;
        background-color: white;
        border-radius: 1em;

        display : flex;
        flex-direction : column;
        justify-content: center;
        align-items: center;
        transition: all .2s ease-in-out;
    }

    .nft-md:hover{
        box-shadow: 2vh 2vh 1vh rgba(25, 25, 25, 0.8);
        transform: scale(1.05);
    }

    .nft-picture-md{
        width : 100%;
        min-width: 100%;
        height : 11.25vw;
        min-height : 11.25vw;
        background-repeat: 'no-repeat';
        object-fit : cover;
        padding: 0;
        margin: 0;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
    }

    .nft-body-md{
        width : 100%;
        min-width: 100%;
        height : 13.75vw;
        min-height : 13.75vw;
        padding: 0;
        margin: 0;
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em;

        display : flex;
        flex-direction : column;
        justify-content: center;
        align-items: flex-start;
    }

    .nft-description-md{
        width : 100%;
        min-width: 100%;
        height : 8.75vw;
        min-height : 8.75vw;
        padding: 0;
        margin: 0;
        background-color: white;
        color:#696969;
        font-size: 0.73em;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: flex-start;
    }

    .nft-bottom-md{
        width : 100%;
        min-width: 100%;
        height : 3vw;
        min-height : 3vw;
        padding: 0;
        margin: 0;
        background-color: white;
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
    }

    .nft-bottom-button-md{
        width : 5vw;
        min-width: 5vw;
        height : 2vw;
        min-height : 2vw;
        padding: 0;
        margin: 0.5vw;
        background-color: #17824e;
        border-style: none;
        border-radius: 0.6em;
        color: white;
        font-size: 0.73em;
        transition: all .2s ease-in-out;
    }

    .nft-bottom-button-md:hover{
        box-shadow: 0.2vw 0.2vw 0.1vw 0.05vw rgba(141, 141, 139, 0.3);
        transform: scale(1.05);
        opacity: 0.8;
    }

    .nft-bottom-button-border-md{
        width : 5vw;
        min-width: 5vw;
        height : 2vw;
        min-height : 2vw;
        padding: 0;
        margin: 0.5vw;
        background-color: white;
        border-style: double;
        border-color: #17824e;
        border-radius: 0.6em;
        color: #17824e;
        font-size: 0.73em;
        transition: all .2s ease-in-out;
    }

    .nft-bottom-button-border-md:hover{
        box-shadow: 0.2vw 0.2vw 0.1vw 0.05vw rgba(141, 141, 139, 0.3);
        transform: scale(1.05);
        opacity: 0.75;
    }

    .nft-content{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width : 100%;
        min-width: 100%;
        margin : 0;
        padding: 0;
    }

    .nft-content-picture{
        width : 8vh;
        min-width: 8vh;
        height : 8vh;
        min-height : 8vh;
        background-repeat: 'no-repeat';
        object-fit : 'cover';
        margin-left: 1vw;
        padding: 0;
    }

    .nft-content-transaction-group{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width : 25%;
        min-width: 25%;
    }

    .nft-content-transaction-group-xl{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width : 50%;
        min-width: 50%;
    }

}

/* 
    ## DESKTOP 15''
*/
@media (min-width: 1020px) and (max-width: 1919px) {

    .nft-md{
        width : 25vw;
        min-width: 25vw;
        height : 35vw;
        min-height : 35vw;
        padding: 0;
        margin: 1vw;
        background-color: white;
        border-radius: 1em;

        display : flex;
        flex-direction : column;
        justify-content: center;
        align-items: center;
        transition: all .2s ease-in-out;
    }

    .nft-md:hover{
        box-shadow: 2vh 2vh 1vh rgba(25, 25, 25, 0.8);
        transform: scale(1.05);
    }

    .nft-picture-md{
        width : 100%;
        min-width: 100%;
        height : 14.06vw;
        min-height : 14.06vw;
        background-repeat: 'no-repeat';
        object-fit : cover;
        padding: 0;
        margin: 0;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
    }

    .nft-body-md{
        width : 100%;
        min-width: 100%;
        height : 20.96vw;
        min-height : 20.96vw;
        padding: 0;
        margin: 0;
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em;

        display : flex;
        flex-direction : column;
        justify-content: center;
        align-items: flex-start;
    }

    .nft-description-md{
        width : 100%;
        min-width: 100%;
        height : 13.96vw;
        min-height : 13.96vw;
        padding: 0;
        margin-top: 0;
        background-color: white;
        color:#696969;
        font-size: 0.73em;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: flex-start;
    }

    .nft-bottom-md{
        width : 100%;
        min-width: 100%;
        height : 5vw;
        min-height : 5vw;
        padding: 0;
        margin: 0;
        background-color: white;
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
    }

    .nft-bottom-button-md{
        width : 8vw;
        min-width: 8vw;
        height : 3vw;
        min-height : 3vw;
        padding: 0;
        margin: 0.5vw;
        background-color: #17824e;
        border-style: none;
        border-radius: 0.6em;
        color: white;
        font-size: 0.73em;
        transition: all .2s ease-in-out;
    }

    .nft-bottom-button-md:hover{
        box-shadow: 0.2vw 0.2vw 0.1vw 0.05vw rgba(141, 141, 139, 0.3);
        transform: scale(1.05);
        opacity: 0.75;
    }

    .nft-bottom-button-border-md{
        width : 8vw;
        min-width: 8vw;
        height : 3vw;
        min-height : 3vw;
        padding: 0;
        margin: 0.5vw;
        background-color: white;
        border-style: double;
        border-color: #17824e;
        border-radius: 0.6em;
        color: #17824e;
        font-size: 0.73em;
        transition: all .2s ease-in-out;
    }

    .nft-bottom-button-border-md:hover{
        box-shadow: 0.2vw 0.2vw 0.1vw 0.05vw rgba(141, 141, 139, 0.3);
        transform: scale(1.05);
        opacity: 0.75;
    }

    .nft-content{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width : 100%;
        min-width: 100%;
        margin : 0;
        padding: 0;
    }

    .nft-content-picture{
        width : 8vh;
        min-width: 8vh;
        height : 8vh;
        min-height : 8vh;
        background-repeat: 'no-repeat';
        object-fit : 'cover';
        margin-left: 1vw;
        padding: 0;
    }

    .nft-content-transaction-group{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width : 25%;
        min-width: 25%;
    }

    .nft-content-transaction-group-xl{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width : 50%;
        min-width: 50%;
    }

}

/* 
    ## IPAD
*/
@media (min-width: 820px) and (max-width: 1019px) {

    .nft-md{
        width : 40vw;
        min-width: 40vw;
        height : 55vw;
        min-height : 55vw;
        padding: 0;
        margin: 2vw;
        background-color: white;
        border-radius: 1em;

        display : flex;
        flex-direction : column;
        justify-content: center;
        align-items: center;
        box-shadow: 2vh 2vh 1vh rgba(25, 25, 25, 0.8);
    }

    .nft-picture-md{
        width : 100%;
        min-width: 100%;
        height : 22.5vw;
        min-height : 22.5vw;
        background-repeat: 'no-repeat';
        object-fit : cover;
        padding: 0;
        margin: 0;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
    }

    .nft-body-md{
        width : 100%;
        min-width: 100%;
        height : 32.5vw;
        min-height : 32.5vw;
        padding: 0;
        margin: 0;
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em;

        display : flex;
        flex-direction : column;
        justify-content: center;
        align-items: flex-start;
    }

    .nft-description-md{
        width : 100%;
        min-width: 100%;
        height : 24.5vw;
        min-height : 24.5vw;
        padding: 0;
        margin: 0;
        background-color: white;
        color:#696969;
        font-size: 0.73em;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: flex-start;
    }

    .nft-bottom-md{
        width : 100%;
        min-width: 100%;
        height : 5vw;
        min-height : 5vw;
        padding: 0;
        margin: 0;
        background-color: white;
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
    }

    .nft-bottom-button-md{
        width : 10vw;
        min-width: 10vw;
        height : 3vw;
        min-height : 3vw;
        padding: 0;
        margin: 0.5vw;
        background-color: #17824e;
        border-style: none;
        border-radius: 0.6em;
        color: white;
        font-size: 0.73em;
        box-shadow: 0.5vh 0.5vh 0.25vh rgba(105, 105, 105, 0.5);
    }

    .nft-bottom-button-border-md{
        width : 10vw;
        min-width: 10vw;
        height : 3vw;
        min-height : 3vw;
        padding: 0;
        margin: 0.5vw;
        background-color: white;
        border-style: double;
        border-color: #17824e;
        border-radius: 0.6em;
        color: #17824e;
        font-size: 0.73em;
        box-shadow: 0.5vh 0.5vh 0.25vh rgba(105, 105, 105, 0.5);
    }

    .nft-content{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width : 100%;
        min-width: 100%;
        margin : 0;
        padding: 0;
    }

    .nft-content-picture{
        width : 8vh;
        min-width: 8vh;
        height : 8vh;
        min-height : 8vh;
        background-repeat: 'no-repeat';
        object-fit : 'cover';
        margin-left: 1vw;
        padding: 0;
    }

    .nft-content-transaction-group{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width : 25%;
        min-width: 25%;
    }

    .nft-content-transaction-group-xl{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width : 50%;
        min-width: 50%;
    }

}

/* 
    ## SMARTPHONE
*/
@media (max-width: 819px) {

    .nft-md{
        width : 80vw;
        min-width: 80vw;
        height : 120vw;
        min-height : 120vw;
        padding: 0;
        margin: 2vw;
        margin-bottom: 4vw;
        background-color: white;
        border-radius: 1em;

        display : flex;
        flex-direction : column;
        justify-content: center;
        align-items: center;
        box-shadow: 2vh 2vh 1vh rgba(25, 25, 25, 0.8);
    }

    .nft-picture-md{
        width : 100%;
        min-width: 100%;
        height : 45vw;
        min-height : 45vw;
        background-repeat: 'no-repeat';
        object-fit : cover;
        padding: 0;
        margin: 0;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
    }

    .nft-body-md{
        width : 100%;
        min-width: 100%;
        height : 75vw;
        min-height : 75vw;
        padding: 0;
        margin: 0;
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em;

        display : flex;
        flex-direction : column;
        justify-content: center;
        align-items: flex-start;
    }

    .nft-description-md{
        width : 100%;
        min-width: 100%;
        height : 54vw;
        min-height : 54vw;
        padding: 0;
        margin: 0;
        background-color: white;
        color:#696969;
        font-size: 0.73em;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: flex-start;
    }

    .nft-bottom-md{
        width : 100%;
        min-width: 100%;
        height : 15vw;
        min-height : 15vw;
        padding: 0;
        margin: 0;
        background-color: white;
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em;

        display : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
    }

    .nft-bottom-button-md{
        width : 20vw;
        min-width: 20vw;
        height : 8vw;
        min-height : 8vw;
        padding: 0;
        margin: 1vw;
        background-color: #17824e;
        border-style: none;
        border-radius: 0.6em;
        color: white;
        font-size: 0.73em;
        box-shadow: 0.5vh 0.5vh 0.25vh rgba(105, 105, 105, 0.5);
    }

    .nft-bottom-button-border-md{
        width : 20vw;
        min-width: 20vw;
        height : 8vw;
        min-height : 8vw;
        padding: 0;
        margin: 1vw;
        background-color: white;
        border-style: double;
        border-color: #17824e;
        border-radius: 0.6em;
        color: #17824e;
        font-size: 0.73em;
        box-shadow: 0.5vh 0.5vh 0.25vh rgba(105, 105, 105, 0.5);
    }

    .nft-content{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width : 100%;
        min-width: 100%;
        margin : 0;
        padding: 0;
    }

    .nft-content-picture{
        width : 8vh;
        min-width: 8vh;
        height : 8vh;
        min-height : 8vh;
        background-repeat: 'no-repeat';
        object-fit : 'cover';
        margin-left: 1vw;
        padding: 0;
    }

    .nft-content-transaction-group{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width : 25%;
        min-width: 25%;
    }

    .nft-content-transaction-group-xl{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width : 50%;
        min-width: 50%;
    }

}