/* 
    ## DESKTOP 17''
*/
@media (min-width: 1920px) {

    .nav-logo{
        width: 25vw;
        min-width: 25vw;
        object-fit: contain;
    }

    .nav-wallet{
        width: 25vw;
        min-width: 25vw;
        object-fit: none;
    }

    .nav-wallet:hover{
        transform: scale(1.1);
    }

    .nav-panel{
        background-color: rgba(255, 255, 255, 0.15);
        height : 7vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    a:link { 
        text-decoration: none; 
    } 
    a:visited { 
        text-decoration: none; 
    } 
    a:hover { 
        text-decoration: none; 
    } 
    a:active { 
        text-decoration: none; 
    }

    .nav-panel-item{
        font-size: 1em;
        font-weight: bold;
        color: white;
        margin-top: 2vh;
        margin-left: 3vw;
        margin-right: 3vw;
        text-decoration: none;
    }

    .nav-panel-item:hover{
        color: #696969;
    }

    .nav-panel-item-bold{
        font-size: 1em;
        font-weight: bold;
        color: transparent;
        -webkit-text-stroke: 1px white; /* width and color */
        margin-top: 2vh;
        margin-left: 3vw;
        margin-right: 3vw;
    }

    .nav-panel-item-bold:hover{
        color: transparent;
        -webkit-text-stroke: 1px #696969; /* width and color */
    }

    .nav-panel-item-bnt{
        font-size: 1em;
        font-weight: bold;
        color: white;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        margin-left: 5vw;
        margin-right: 5vw;
        outline: 1px solid white;
        box-shadow: 1px 1px 12px #555;
    }

    .nav-panel-item-bnt:hover{
        color: #696969;
    }
}

/* 
    ## DESKTOP 15''
*/
@media (min-width: 1020px) and (max-width: 1919px) {

    .nav-logo{
        width: 25vw;
        min-width: 25vw;
        object-fit: contain;
    }
    .nav-wallet{
        width: 25vw;
        min-width: 25vw;
        object-fit: none;
    }

    .nav-wallet:hover{
        transform: scale(1.1);
    }

    .nav-panel{
        background-color: rgba(255, 255, 255, 0.15);
        height : 7vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    a:link { 
        text-decoration: none; 
    } 
    a:visited { 
        text-decoration: none; 
    } 
    a:hover { 
        text-decoration: none; 
    } 
    a:active { 
        text-decoration: none; 
    }

    .nav-panel-item{
        font-size: 0.73em;
        font-weight: bold;
        color: white;
        margin-top: 2vh;
        margin-left: 3vw;
        margin-right: 3vw;
        text-decoration: none;
    }

    .nav-panel-item:hover{
        color: #696969;
    }

    .nav-panel-item-bold{
        font-size: 0.73em;
        font-weight: bold;
        color: white;
        margin-top: 2vh;
        margin-left: 3vw;
        margin-right: 3vw;
        text-decoration: none;
    }

    .nav-panel-item-bold:hover{
        color: transparent;
        -webkit-text-stroke: 1px #696969; /* width and color */
    }

    .nav-panel-item-bnt{
        font-size: 0.73em;
        font-weight: bold;
        color: white;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        margin-left: 3vw;
        margin-right: 3vw;
        outline: 1px solid white;
        box-shadow: 1px 1px 12px #555;
    }

    .nav-panel-item-bnt:hover{
        color: #696969;
    }
}

/* 
    ## IPAD
*/
@media (min-width: 820px) and (max-width: 1019px) {

    .nav-logo{
        width: 33vw;
        min-width: 33vw;
        object-fit: contain;
    }

    .nav-wallet{
        width: 33vw;
        min-width: 33vw;
        object-fit: none;
    }

    .nav-wallet:hover{
        transform: scale(1.1);
    }

    .nav-panel{
        background-color: rgba(255, 255, 255, 0.15);
        height : 7vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    a:link { 
        text-decoration: none; 
    } 
    a:visited { 
        text-decoration: none; 
    } 
    a:hover { 
        text-decoration: none; 
    } 
    a:active { 
        text-decoration: none; 
    }

    .nav-panel-item{
        font-size: 0.73em;
        font-weight: bold;
        color: white;
        margin-top: 3vh;
        margin-left: 1em;
        margin-right: 1em;
    }

    .nav-panel-item:hover{
        color: #696969;
    }

    .nav-panel-item-bold{
        font-size: 0.73em;
        font-weight: bold;
        color: white;
        margin-top: 2vh;
        margin-left: 1em;
        margin-right: 1em;
    }

    .nav-panel-item-bold:hover{
        color: transparent;
        -webkit-text-stroke: 1px #696969; /* width and color */
    }

    .nav-panel-item-bnt{
        font-size: 0.73em;
        font-weight: bold;
        color: white;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        margin-left: 1em;
        margin-right: 1em;
        outline: 1px solid white;
        box-shadow: 1px 1px 12px #555;
    }

    .nav-panel-item-bnt:hover{
        color: #696969;
    }
    
}

/* 
    ## SMARTPHONE
*/
@media (max-width: 819px) {

    .nav-logo{
        width: 25vw;
        min-width: 25vw;
        object-fit: contain;
    }

    .nav-wallet{
        width: 25vw;
        min-width: 25vw;
        object-fit: none;
    }

    .nav-wallet:hover{
        transform: scale(1.1);
    }

    .nav-panel{
        background-color: rgba(255, 255, 255, 0.15);
        height : 7vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    a:link { 
        text-decoration: none; 
    } 
    a:visited { 
        text-decoration: none; 
    } 
    a:hover { 
        text-decoration: none; 
    } 
    a:active { 
        text-decoration: none; 
    }

    .nav-panel-item{
        font-size: 0.6em;
        font-weight: bold;
        color: white;
        margin-top: 2.5vh;
        margin-left: 0.5em;
        margin-right: 0.5em;
    }

    .nav-panel-item:hover{
        color: #696969;
    }

    .nav-panel-item-bold{
        font-size: 0.6em;
        font-weight: bold;
        color: white;
        margin-top: 2vh;
        margin-left: 0.5em;
        margin-right: 0.5em;
    }

    .nav-panel-item-bold:hover{
        color: transparent;
        -webkit-text-stroke: 1px #696969; /* width and color */
    }

    .nav-panel-item-bnt{
        font-size: 0.6em;
        font-weight: bold;
        color: white;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        margin-left: 0.5em;
        margin-right: 0.5em;
        outline: 1px solid white;
        box-shadow: 1px 1px 12px #555;
    }

    .nav-panel-item-bnt:hover{
        color: #696969;
    }
    
}