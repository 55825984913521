.perspective-container{
  min-width: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display : flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.perspective-row{
  min-width: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display : flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.perspective-block-50-left{
  min-width: 50%;
  width: 50%;
  margin: 0;
  padding: 0;
  display : flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.perspective-block-50-right{
  min-width: 50%;
  width: 50%;
  margin: 0;
  padding: 0;
  display : flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}




/* 
    ## DESKTOP 17''
*/
@media (min-width: 1920px) {

    .perspective-space{
      height: 5vw;
      min-height: 5vw;
    }
  
  }
  
  /* 
    ## DESKTOP 15''
  */
  @media (min-width: 1020px) and (max-width: 1919px) {
  
    .perspective-space{
      height: 8vw;
      min-height: 8vw;
    }
    
  }
  
  /* 
    ## IPAD
  */
  @media (min-width: 820px) and (max-width: 1019px) {
  
    .perspective-space{
      height: 12vw;
      min-height: 12vw;
    }

    .MuiChartsLegend-series text{
      font-size: 1.5vw !important;
      color : #696969 !important;
    }
    
  }
  
  /* 
    ## SMARTPHONE
  */
  @media (max-width: 819px) {
  
    .perspective-space{
      height: 20vw;
      min-height: 20vw;
    }

    .MuiChartsLegend-series text{
      font-size: 2vw !important;
      color : #696969 !important;
    }
    
  }