

/* 
    ## DESKTOP 17''
*/
@media (min-width: 1920px) {

    .circle-button-component-lr-margin{
        margin : 0vh 1vw 0vh 1vw;
        padding: 0;
    }

    .circle-button-component{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width : 2vw;
        min-width: 2vw;
        height : 2vw;
        min-height : 2vw;
        background-color : #48b27f;
        color: white;
        border-radius: 50%;
        border: none;
        font-size: 1vw;
    }

    .circle-button-component:hover{
        background-color : #dbdbdb;
        color: #696969;
    }

    .circle-button-component-selected{
        animation: blink-selected 1s normal;
    }

    .circle-button-component-clicked{
        animation: blink-clicked 1s normal;
    }

    @keyframes blink-selected {
        from { opacity: 0 }
        to { opacity: 1 }
    }

    @keyframes blink-clicked {
        from { opacity: 0 }
        to { opacity: 1 }
    }
 
}

/* 
    ## DESKTOP 15''
*/
@media (min-width: 1020px) and (max-width: 1919px) {

    .circle-button-component-lr-margin{
        margin : 0vh 1vw 0vh 1vw;
        padding: 0;
    }

    .circle-button-component{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width : 2vw;
        min-width: 2vw;
        height : 2vw;
        min-height : 2vw;
        background-color : #48b27f;
        color: white;
        border-radius: 50%;
        border: none;
        font-size: 1vw;
    }

    .circle-button-component:hover{
        background-color : #dbdbdb;
        color: #696969;
    }

    .circle-button-component-selected{
        animation: blink-selected 1s normal;
    }

    .circle-button-component-clicked{
        animation: blink-clicked 1s normal;
    }

    @keyframes blink-selected {
        from { opacity: 0 }
        to { opacity: 1 }
    }

    @keyframes blink-clicked {
        from { opacity: 0 }
        to { opacity: 1 }
    }

}

/* 
    ## IPAD
*/
@media (min-width: 820px) and (max-width: 1019px) {

    .circle-button-component-lr-margin{
        margin : 0vh 1vw 0vh 1vw;
        padding: 0;
    }

    .circle-button-component{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 3vw;
        width: 3vw;
        min-height: 3vw;
        height: 3vw;
        background-color : #48b27f;
        color: white;
        border-radius: 50%;
        border: none;
        font-size: 1vw;
    }

    .circle-button-component:hover{
        background-color : #dbdbdb;
        color: #696969;
    }

    .circle-button-component-selected{
        animation: blink-selected 1s normal;
    }

    .circle-button-component-clicked{
        animation: blink-clicked 1s normal;
    }

    @keyframes blink-selected {
        from { opacity: 0 }
        to { opacity: 1 }
    }

    @keyframes blink-clicked {
        from { opacity: 0 }
        to { opacity: 1 }
    }

}

/* 
    ## SMARTPHONE
*/
@media (max-width: 819px) {

    .circle-button-component-lr-margin{
        margin : 0vh 1vw 0vh 1vw;
        padding: 0;
    }

    .circle-button-component{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 3vw;
        width: 3vw;
        min-height: 3vw;
        height: 3vw;
        background-color : #48b27f;
        color: white;
        border-radius: 50%;
        border: none;
        font-size: 1vw;
    }

    .circle-button-component:hover{
        background-color : #dbdbdb;
        color: #696969;
    }

    .circle-button-component-selected{
        animation: blink-selected 1s normal;
    }

    .circle-button-component-clicked{
        animation: blink-clicked 1s normal;
    }

    @keyframes blink-selected {
        from { opacity: 0 }
        to { opacity: 1 }
    }

    @keyframes blink-clicked {
        from { opacity: 0 }
        to { opacity: 1 }
    }

}