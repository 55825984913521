

/* 
    ## DESKTOP 17''
*/
@media (min-width: 1920px) {

    .round-button-component-lr-margin{
        margin : 0vh 1vw 0vh 1vw;
        padding: 0;
    }

    .round-button-component{
        width : 5vw;
        min-width: 5vw;
        height : 2vw;
        min-height : 2vw;
        padding: 0;
        margin: 0.5vw;
        background-color: white;
        border-style: solid;
        border-width: 0.2em;
        border-color: #17824e;
        border-radius: 0.6em;
        color: #17824e;
        font-size: 0.73em;
        transition: all .2s ease-in-out;
    }

    .round-button-component:hover{
        box-shadow: 0.2vw 0.2vw 0.1vw 0.05vw rgba(141, 141, 139, 0.3);
        transform: scale(1.05);
        opacity: 0.75;
    }

    .round-button-component-reverse{
        width : 5vw;
        min-width: 5vw;
        height : 2vw;
        min-height : 2vw;
        padding: 0;
        margin: 0.5vw;
        background-color: #17824e;
        border-style: solid;
        border-width: 0.2em;
        border-color: #17824e;
        border-radius: 0.6em;
        color: white;
        font-size: 0.73em;
        transition: all .2s ease-in-out;
    }

    .round-button-component-reverse:hover{
        box-shadow: 0.2vw 0.2vw 0.1vw 0.05vw rgba(141, 141, 139, 0.3);
        transform: scale(1.05);
        opacity: 0.75;
    }

    .round-button-component-selected{
        animation: blink-selected 1s normal;
    }

    .round-button-component-clicked{
        animation: blink-clicked 1s normal;
    }

    @keyframes blink-selected {
        from { opacity: 0 }
        to { opacity: 1 }
    }

    @keyframes blink-clicked {
        from { opacity: 0 }
        to { opacity: 1 }
    }
 
}

/* 
    ## DESKTOP 15''
*/
@media (min-width: 1020px) and (max-width: 1919px) {

    .round-button-component-lr-margin{
        margin : 0vh 1vw 0vh 1vw;
        padding: 0;
    }

    .round-button-component{
        width : 8vw;
        min-width: 8vw;
        height : 3vw;
        min-height : 3vw;
        padding: 0;
        margin: 0.5vw;
        background-color: white;
        border-style: solid;
        border-width: 0.2em;
        border-color: #17824e;
        border-radius: 0.6em;
        color: #17824e;
        font-size: 0.73em;
        transition: all .2s ease-in-out;
    }

    .round-button-component:hover{
        box-shadow: 0.2vw 0.2vw 0.1vw 0.05vw rgba(141, 141, 139, 0.3);
        transform: scale(1.05);
        opacity: 0.75;
    }

    .round-button-component-reverse{
        width : 8vw;
        min-width: 8vw;
        height : 3vw;
        min-height : 3vw;
        padding: 0;
        margin: 0.5vw;
        background-color: #17824e;
        border-style: solid;
        border-width: 0.2em;
        border-color: #17824e;
        border-radius: 0.6em;
        color: white;
        font-size: 0.73em;
        transition: all .2s ease-in-out;
    }

    .round-button-component-reverse:hover{
        box-shadow: 0.2vw 0.2vw 0.1vw 0.05vw rgba(141, 141, 139, 0.3);
        transform: scale(1.05);
        opacity: 0.75;
    }

    .round-button-component-selected{
        animation: blink-selected 1s normal;
    }

    .round-button-component-clicked{
        animation: blink-clicked 1s normal;
    }

    @keyframes blink-selected {
        from { opacity: 0 }
        to { opacity: 1 }
    }

    @keyframes blink-clicked {
        from { opacity: 0 }
        to { opacity: 1 }
    }

}

/* 
    ## IPAD
*/
@media (min-width: 820px) and (max-width: 1019px) {

    .round-button-component-lr-margin{
        margin : 0vh 1vw 0vh 1vw;
        padding: 0;
    }

    .round-button-component{
        width : 10vw;
        min-width: 10vw;
        height : 3vw;
        min-height : 3vw;
        padding: 0;
        margin: 0.5vw;
        background-color: white;
        border-style: solid;
        border-width: 0.2em;
        border-color: #17824e;
        border-radius: 0.6em;
        color: #17824e;
        font-size: 0.73em;
        box-shadow: 0.5vh 0.5vh 0.25vh rgba(105, 105, 105, 0.5);
    }

    .round-button-component:hover{
        background-color : #dbdbdb;
        color: #696969;
    }

    .round-button-component-reverse{
        width : 10vw;
        min-width: 10vw;
        height : 3vw;
        min-height : 3vw;
        padding: 0;
        margin: 0.5vw;
        background-color: #17824e;
        border-style: solid;
        border-width: 0.2em;
        border-color: #17824e;
        border-radius: 0.6em;
        color: white;
        font-size: 0.73em;
        box-shadow: 0.5vh 0.5vh 0.25vh rgba(105, 105, 105, 0.5);
    }

    .round-button-component-reverse:hover{
        background-color : #dbdbdb;
        color: #696969;
    }

    .round-button-component-selected{
        animation: blink-selected 1s normal;
    }

    .round-button-component-clicked{
        animation: blink-clicked 1s normal;
    }

    @keyframes blink-selected {
        from { opacity: 0 }
        to { opacity: 1 }
    }

    @keyframes blink-clicked {
        from { opacity: 0 }
        to { opacity: 1 }
    }

}

/* 
    ## SMARTPHONE
*/
@media (max-width: 819px) {

    .round-button-component-lr-margin{
        margin : 0vh 1vw 0vh 1vw;
        padding: 0;
    }

    .round-button-component{
        width : 20vw;
        min-width: 20vw;
        height : 8vw;
        min-height : 8vw;
        padding: 0;
        margin: 1vw;
        background-color: white;
        border-style: solid;
        border-width: 0.2em;
        border-color: #17824e;
        border-radius: 0.6em;
        color: #17824e;
        font-size: 0.73em;
        box-shadow: 0.5vh 0.5vh 0.25vh rgba(105, 105, 105, 0.5);
    }


    .round-button-component:hover{
        background-color : #dbdbdb;
        color: #696969;
    }

    .round-button-component-reverse{
        width : 20vw;
        min-width: 20vw;
        height : 8vw;
        min-height : 8vw;
        padding: 0;
        margin: 1vw;
        background-color: #17824e;
        border-style: solid;
        border-width: 0.2em;
        border-color: #17824e;
        border-radius: 0.6em;
        color: white;
        font-size: 0.73em;
        box-shadow: 0.5vh 0.5vh 0.25vh rgba(105, 105, 105, 0.5);
    }


    .round-button-component-reverse:hover{
        background-color : #dbdbdb;
        color: #696969;
    }

    .round-button-component-selected{
        animation: blink-selected 1s normal;
    }

    .round-button-component-clicked{
        animation: blink-clicked 1s normal;
    }

    @keyframes blink-selected {
        from { opacity: 0 }
        to { opacity: 1 }
    }

    @keyframes blink-clicked {
        from { opacity: 0 }
        to { opacity: 1 }
    }

}