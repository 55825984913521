
.salebox-instructions{
    background-color: #17824e;
    border-radius: 1vw;
    padding: 1vw;
}


/* 
    ## DESKTOP 17''
*/
@media (min-width: 1920px) {

    .salebox-component-card-box-title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        width: 100%;
        min-width: 100%;
        height: 8vh;
        min-height: 8vh;
    }

    .paybox-title{
        position : relative; 
        top : 6vh;
        font-size: 2.0em;
        color : white;
    }

    .salebox-component-card {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: transparent;
        min-width: 20vw;
        width: 20vw;
        min-height: 30vh;
        height: 30vh;
        margin-top: 5vh;
    }
    
    .salebox-component-card-icon {
        z-index: 1;
        object-fit: cover;
        width: 12vh;
        min-width: 12vh;
        height: 12vh;
        min-height: 12vh;
    }
    
    .salebox-component-card-body {
        z-index: 0;
        position: relative;
        top: -6vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: #17824e;
        border-radius: 0.5em;
        width: 20vw;
        min-width: 20vw;
        height: 40vh;
        min-height: 40vh;
    }
    
    .salebox-component-card-title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 1.3em;
        color: white;
        text-align: center;
        width: 16vw;
        min-width: 16vw;
        height: 5vh;
        min-height: 5vh;
        margin-top: 8vh;
        margin-left: 1vw;
        margin-right: 1vw;
    }

    .salebox-component-card-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        width: 13vw;
        min-width: 13vw;
        height: 8vh;
        min-height: 8vh;
        margin-top: 1vh;
        margin-left: 1vh;
        margin-right: 1vh;
    }

    .salebox-component-arrow-card-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        width: 13vw;
        min-width: 13vw;
        margin: 0;
        padding: 0;
    }
    
    .salebox-component-card-input {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #023020;
        border-radius: 0.7em;
        border-style: none;
        font-size: 0.8vw;
        color: white;
        text-align: center;
        width: 9vw;
        min-width: 9vw;
        height: 5vh;
        min-height: 5vh;
        margin-left: 1vw;
        margin-right: 1vw;
    }
    
    .crypto-img {
        position: absolute;
        right: 15.5vw;
        width: 6vh;
        min-width: 6vh;
        height: 6vh;
        min-height: 6vh;
        object-fit: fill;
    }

    .crypto-arrow-img {
        position: absolute;
        width: 2vh;
        min-width: 2vh;
        height: 2vh;
        min-height: 2vh;
        object-fit: fill;
    }
    
    .salebox-btn-custom {
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: white;
        width: 7vw;
        background: #48b27f;
        border: none;
        font-size: 0.73em;
        height: 44px;
        margin-top: 1vh;
        margin-left: 1vw;
        margin-right: 1vw;
        border-radius: 0.5em;
        pointer-events: inherit;
        transition: 200ms;
        cursor: pointer;
    }

    .salebox-btn-custom:hover {
        color: white;
        filter: brightness(1.2);
    }

    .salebox-btn-custom:active {
        color: white;
        filter: brightness(4.0);
    }

}

/* 
    ## DESKTOP 15''
*/
@media (min-width: 1020px) and (max-width: 1919px) {

    .salebox-component-card-box-title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        width: 100%;
        min-width: 100%;
        height: 8vh;
        min-height: 8vh;
    }

    .paybox-title{
        position : relative; 
        top : 6vh;
        font-size: 2.0em;
        color : white;
    }

    .salebox-component-card {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: transparent;
        min-width: 20vw;
        width: 20vw;
        min-height: 30vh;
        height: 30vh;
        margin-top: 5vh;
    }
    
    .salebox-component-card-icon {
        z-index: 1;
        object-fit: cover;
        width: 12vh;
        min-width: 12vh;
        height: 12vh;
        min-height: 12vh;
    }
    
    .salebox-component-card-body {
        z-index: 0;
        position: relative;
        top: -6vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: #17824e;
        border-radius: 0.5em;
        width: 20vw;
        min-width: 20vw;
        height: 40vh;
        min-height: 40vh;
    }
    
    .salebox-component-card-title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 1.3em;
        color: white;
        text-align: center;
        width: 16vw;
        min-width: 16vw;
        height: 5vh;
        min-height: 5vh;
        margin-top: 8vh;
        margin-left: 1vw;
        margin-right: 1vw;
    }

    .salebox-component-card-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        width: 13vw;
        min-width: 13vw;
        height: 8vh;
        min-height: 8vh;
        margin-top: 1vh;
        margin-left: 1vh;
        margin-right: 1vh;
    }

    .salebox-component-arrow-card-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        width: 13vw;
        margin: 0;
        padding: 0;
    }
    
    .salebox-component-card-input {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #023020;
        border-radius: 0.7em;
        border-style: none;
        font-size: 0.8vw;
        color: white;
        text-align: center;
        width: 9vw;
        min-width: 9vw;
        height: 5vh;
        min-height: 5vh;
        margin-left: 1vw;
        margin-right: 1vw;
    }
    
    .crypto-img {
        position: absolute;
        right: 15.5vw;
        width: 6vh;
        min-width: 6vh;
        height: 6vh;
        min-height: 6vh;
        object-fit: fill;
    }

    .crypto-arrow-img {
        position: absolute;
        width: 2vh;
        min-width: 2vh;
        height: 2vh;
        min-height: 2vh;
        object-fit: fill;
    }
    
    .salebox-btn-custom {
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: white;
        width: 7vw;
        background: #48b27f;
        border: none;
        font-size: 0.73em;
        height: 44px;
        margin-top: 1vh;
        margin-left: 1vw;
        margin-right: 1vw;
        border-radius: 0.5em;
        pointer-events: inherit;
        transition: 200ms;
        cursor: pointer;
    }

    .salebox-btn-custom:hover {
        color: white;
        filter: brightness(1.2);
    }

    .salebox-btn-custom:active {
        color: white;
        filter: brightness(4.0);
    }

}

/* 
    ## IPAD
*/
@media (min-width: 820px) and (max-width: 1019px) {

    .salebox-component-card-box-title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        width: 100%;
        min-width: 100%;
        height: 8vh;
        min-height: 8vh;
    }

    .paybox-title{
        position : relative; 
        top : 8vh;
        font-size: 2.0em;
        color : white;
    }

    .salebox-component-card {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: transparent;
        min-width: 50vw;
        width: 50vw;
        min-height: 30vh;
        height: 30vh;
        margin-top: 5vh;
    }
    
    .salebox-component-card-icon {
        z-index: 1;
        object-fit: cover;
        width: 10vh;
        min-width: 10vh;
        height: 10vh;
        min-height: 10vh;
    }
    
    .salebox-component-card-body {
        z-index: 0;
        position: relative;
        top: -5vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: #17824e;
        border-radius: 0.5em;
        width: 50vw;
        min-width: 50vw;
        height: 40vh;
        min-height: 40vh;
    }
    
    .salebox-component-card-title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 1.3em;
        color: white;
        text-align: center;
        width: 40vw;
        min-width: 40vw;
        height: 5vh;
        min-height: 5vh;
        margin-top: 8vh;
        margin-left: 1vw;
        margin-right: 1vw;
    }

    .salebox-component-card-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        width: 40vw;
        min-width: 40vw;
        height: 8vh;
        min-height: 8vh;
        margin-top: 1vh;
        margin-left: 1vh;
        margin-right: 1vh;
    }

    .salebox-component-arrow-card-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        width: 40vw;
        min-width: 40vw;
        margin: 0;
        padding: 0;
    }
    
    .salebox-component-card-input {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #023020;
        border-radius: 0.7em;
        border-style: none;
        font-size: 1.5vw;
        color: white;
        text-align: center;
        width: 25vw;
        min-width: 25vw;
        height: 5vh;
        min-height: 5vh;
        margin-left: 1vw;
        margin-right: 1vw;
    }
    
    .crypto-img {
        position: absolute;
        right: 39.5vw;
        width: 6vh;
        min-width: 6vh;
        height: 6vh;
        min-height: 6vh;
        object-fit: fill;
    }

    .crypto-arrow-img {
        position: absolute;
        width: 2vh;
        min-width: 2vh;
        height: 2vh;
        min-height: 2vh;
        object-fit: fill;
    }
    
    .salebox-btn-custom {
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: white;
        width: 7vw;
        background: #48b27f;
        border: none;
        font-size: 0.73em;
        height: 44px;
        margin-top: 1vh;
        margin-left: 1vw;
        margin-right: 1vw;
        border-radius: 0.5em;
        pointer-events: inherit;
        transition: 200ms;
        cursor: pointer;
    }

    .salebox-btn-custom:hover {
        color: white;
        filter: brightness(1.2);
    }

    .salebox-btn-custom:active {
        color: white;
        filter: brightness(4.0);
    }

}

/* 
    ## SMARTPHONE
*/
@media (max-width: 819px) {

    .salebox-component-card-box-title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        width: 100%;
        min-width: 100%;
        height: 8vh;
        min-height: 8vh;
    }

    .paybox-title{
        position : relative; 
        top : 6vh;
        font-size: 2.0em;
        color : white;
    }

    .salebox-component-card {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: transparent;
        min-width: 50vw;
        width: 50vw;
        min-height: 30vh;
        height: 30vh;
        margin-top: 5vh;
    }
    
    .salebox-component-card-icon {
        z-index: 1;
        object-fit: cover;
        width: 10vh;
        min-width: 10vh;
        height: 10vh;
        min-height: 10vh;
    }
    
    .salebox-component-card-body {
        z-index: 0;
        position: relative;
        top: -5vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: #17824e;
        border-radius: 0.5em;
        width: 70vw;
        min-width: 70vw;
        height: 40vh;
        min-height: 40vh;
    }
    
    .salebox-component-card-title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 1.3em;
        color: white;
        text-align: center;
        width: 60vw;
        min-width: 60vw;
        height: 5vh;
        min-height: 5vh;
        margin-top: 8vh;
        margin-left: 1vw;
        margin-right: 1vw;
    }

    .salebox-component-card-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        width: 60vw;
        min-width: 60vw;
        height: 8vh;
        min-height: 8vh;
        margin-top: 1vh;
        margin-left: 1vh;
        margin-right: 1vh;
    }

    .salebox-component-arrow-card-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        width: 60vw;
        min-width: 60vw;
        margin: 0;
        padding: 0;
    }
    
    .salebox-component-card-input {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #023020;
        border-radius: 0.7em;
        border-style: none;
        font-size: 3vw;
        color: white;
        text-align: center;
        width: 35vw;
        min-width: 35vw;
        height: 5vh;
        min-height: 5vh;
        margin-left: 1vw;
        margin-right: 1vw;
    }
    
    .crypto-img {
        position: absolute;
        right: 55vw;
        width: 6vh;
        min-width: 6vh;
        height: 6vh;
        min-height: 6vh;
        object-fit: fill;
    }

    .crypto-arrow-img {
        position: absolute;
        width: 2vh;
        min-width: 2vh;
        height: 2vh;
        min-height: 2vh;
        object-fit: fill;
    }
    
    .salebox-btn-custom {
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: white;
        width: 10vw;
        background: #48b27f;
        border: none;
        font-size: 0.73em;
        height: 44px;
        margin-top: 1vh;
        margin-left: 1vw;
        margin-right: 1vw;
        border-radius: 0.5em;
        pointer-events: inherit;
        transition: 200ms;
        cursor: pointer;
    }

    .salebox-btn-custom:hover {
        color: white;
        filter: brightness(1.2);
    }

    .salebox-btn-custom:active {
        color: white;
        filter: brightness(4.0);
    }

}