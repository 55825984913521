

.scrollbar {
    float: left !important;
    background: transparent !important;
    overflow-y: scroll !important;
}

.scrollbar-sunny-morning {
    scrollbar-color: linear-gradient(120deg, #f1f1ef 0%, #17824e 100%) transparent !important;
    border-radius: 10px !important;
    background: transparent !important;
}
  
.scrollbar-sunny-morning::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1) !important;

  border-radius: 10px !important;
  background: transparent !important;
}
  
.scrollbar-sunny-morning::-webkit-scrollbar {
  width: 12px !important;
  background: transparent !important;
}
  
.scrollbar-sunny-morning::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1) !important;
  background-image: -webkit-linear-gradient(330deg, #f1f1ef 0%, #17824e 100%) !important;
  background-image: linear-gradient(120deg, #f1f1ef 0%, #17824e 100%) !important;
}
