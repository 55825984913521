
.tab-panel-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: transparent;
    min-width: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
}

.tab-panel-header{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: transparent;
    min-width: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    border-bottom:1px;
    border-bottom-color: #48b27f;
}

hr.tab-panel-header-separation {
    min-width: 100%;
    width: 100%;
    border-top: 1px solid #48b27f;
    padding: 0;
    margin: 0;
}

.tab-panel-body{
    background-color: transparent;
    width: 100%;
    min-width: 100%;
    padding: 0;
    margin: 2px;
}

.tab-panel-header-item-wrapper{
    min-width: 33.33%;
    max-width: 33.33%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em 0 0 0;
    margin: 0em;
}

.tab-panel-header-item:hover{
    color: #696969;
}

.tab-panel-header-item.active{
    color: #696969;
    border-bottom: 1px solid #696969;
}

/* 
    ## DESKTOP 17''
*/
@media (min-width: 1920px) {

    .tab-panel-header-item{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #48b27f;
        color: white;
        border-top-left-radius: 2em;
        border-top-right-radius: 2em;
        border: none;
        font-size: 0.73em;
        min-width: 95%;
        max-width: 95%;
        min-height: 2vw;
        max-height: 2vw;
        padding: 0;
        margin: '1vw 0 0 0';
    }
}

/* 
    ## DESKTOP 15''
*/
@media (min-width: 1020px) and (max-width: 1919px) {

    .tab-panel-header-item{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #48b27f;
        color: white;
        border-top-left-radius: 2em;
        border-top-right-radius: 2em;
        border: none;
        font-size: 0.73em;
        min-width: 95%;
        max-width: 95%;
        min-height: 2vw;
        max-height: 2vw;
        padding: 0;
        margin: '1vw 0 0 0';
    }

}

/* 
    ## IPAD
*/
@media (min-width: 820px) and (max-width: 1019px) {

    .tab-panel-header-item{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #48b27f;
        color: white;
        border-top-left-radius: 4em;
        border-top-right-radius: 4em;
        border: none;
        font-size: 0.73em;
        min-width: 95%;
        max-width: 95%;
        min-height: 3vw;
        max-height: 3vw;
        padding: 0;
        margin: '1vw 0 0 0';
    }

}

/* 
    ## SMARTPHONE
*/
@media (max-width: 819px) {

    .tab-panel-header-item{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #48b27f;
        color: white;
        border-top-left-radius: 4em;
        border-top-right-radius: 4em;
        border: none;
        font-size: 0.73em;
        min-width: 95%;
        max-width: 95%;
        min-height: 5vw;
        max-height: 5vw;
        padding: 0;
        margin: '1vw 0 0 0';
    }

}
